import React from 'react';
import { connect } from "react-redux";
import { closeModal } from "../redux";
import { Modal } from "../types";
import BSModal from "react-bootstrap/Modal";

interface ModalContainerProps {
    modals: Modal[];
    closeModal: (id: number) => {};
}

const ModalContainer = ({ modals, closeModal }: ModalContainerProps) => {
    const createClose = (modal: Modal) => () => {
        closeModal(modal.id);
        if (modal.callback) {
            modal.callback();
        }
    };

    return (
        <>
            {modals.map(modal =>
                <BSModal
                    show={true}
                    key={modal.id}
                    onHide={createClose(modal)}
                    centered
                >
                    { modal.header && <BSModal.Header closeButton>
                        <BSModal.Title>{modal.header}
                        </BSModal.Title>
                    </BSModal.Header> }

                    <BSModal.Body>
                        <modal.Component close={createClose(modal)} />
                    </BSModal.Body>

                    {/*<Modal.Footer>*/}
                    {/*  <Button variant="secondary">Close</Button>*/}
                    {/*  <Button variant="primary">Save changes</Button>*/}
                    {/*</Modal.Footer>*/}
                </BSModal>
            )}
        </>
    )
};

// @ts-ignore
const mapStateToProps = (state) => ({
    modals: state.modals || [],
});

const mapDispatchToProps = dispatch => ({
    closeModal: (id: number) => dispatch(closeModal(id)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
