import Dashboard from "./views/Dashboard";
import NewRepo from "./views/NewRepo";

export default {
    dashboard: {
        title: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        includeInNav: true,
        exact: true,
        icon: "someicon",
    },
    newRepo: {
        title: 'New Repo',
        path: '/newrepo',
        component: NewRepo,
        exact: true,
    },
};
