import React from 'react';
import CurrentUser from "../features/users/components/CurrentUser";
import RepoOverview from "../features/repos/components/RepoOverview";

const Home = () =>
    <div className="container">
        <CurrentUser/>
        <RepoOverview/>
    </div>;

export default Home;
