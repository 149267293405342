import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { FlagType } from "../types";
import { connect } from "react-redux";
import sortBy from 'lodash/sortBy';
import { useHistory } from "react-router-dom";

interface FlagsPerFileProps {
    flags: FlagType[],
}

const COLUMNS = [
    {
        dataField: 'file',
        text: 'File'
    },
    {
        dataField: 'flags',
        text: 'Flags'
    },
    {
        dataField: 'lines',
        text: 'Total Lines'
    },
];

const prepareData = flags => {
    const flagsPerFile = {};
    flags.forEach(flag => {
        flagsPerFile[flag.file] = flagsPerFile[flag.file] || { lines: 0, flags: 0 };
        flagsPerFile[flag.file].lines += (flag.until - flag.from + 1);
        flagsPerFile[flag.file].flags += 1;
        flagsPerFile[flag.file].file = flag.file;
    });
    return sortBy(Object.values(flagsPerFile), 'flags').reverse();
};

const FlagsPerFile = ({ flags }: FlagsPerFileProps) => {
    const history = useHistory();
    const data = prepareData(flags);
    return <BootstrapTable
        keyField='id'
        data={data}
        columns={COLUMNS}
        rowEvents={{
            onClick: (e, row, rowIndex) => {
                history.push("/files/" + row.file);
            }
        }}
    />;
};

// @ts-ignore
const mapStateToProps = (state) => ({
    flags: state.flags || [],
});

export default connect(mapStateToProps)(FlagsPerFile)
