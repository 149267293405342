import React from 'react'
import { Button } from "react-bootstrap";

const NewRepo = () =>
    <div className="container">
        <br/>
        <Button>Get from Github</Button><br/><br/>
        <Button>Get from GitLab</Button><br/><br/>
        <Button>Get from BitBucket</Button><br/><br/>
        <Button>Upload Repo</Button><br/><br/>
        <p>Todo...</p>
    </div>;

export default NewRepo
