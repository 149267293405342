import React from 'react';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import {
    Box,
    Button,
    ButtonPrimary,
    SelectMenu,
} from "@primer/components";
import { Form } from 'react-bootstrap'
import styled from "styled-components";
import {FlagOption, Option, PriorityOption, UnsavedFlagType} from "../types";
import { getLineText } from "../utils";
import {
    DEFAULT_FLAG_TYPE,
    DEFAULT_PRIORITY,
    FLAG_TYPE_OPTIONS,
    PRIORITY_OPTIONS
} from "../constants";


interface SelectTypeProps {
    header: string,
    type: Option,
    setType: (type: any) => void,
    options: Option[],
    summary?: string,
}

const PrimerSelect = ({
    header,
    type,
    setType,
    options,
    summary = null,
}: SelectTypeProps) =>
    <SelectMenu>
        <Button as="summary">{summary || type.text}</Button>
        <SelectMenu.Modal>
            <SelectMenu.Header>{header}</SelectMenu.Header>
            <SelectMenu.List>
                { options.map(option =>
                    <SelectMenu.Item
                        key={option.value}
                        selected={type.value === option.value}
                        onClick={() => setType(option)}
                    >{option.text}</SelectMenu.Item>
                ) }
            </SelectMenu.List>
        </SelectMenu.Modal>
    </SelectMenu>;

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

interface InitialFlagData {
    id?: number,
    from: number,
    until: number,
    file: string
    priority?: PriorityOption,
    type?: FlagOption,
    description?: string
}

interface FlagFormProps {
    submit: (flag: UnsavedFlagType) => void;
    flag: InitialFlagData;
}

const FlagForm = ({ submit, flag }: FlagFormProps) => {
    const [description, setDescription] = React.useState(flag.description || '');
    const [type, setType] = React.useState(flag.type || DEFAULT_FLAG_TYPE);
    const [priority, setPriority] = React.useState(flag.priority || DEFAULT_PRIORITY);
    const save = () => submit({ ...flag, priority, type, description });
    return (
        <Box p={4}>
            <Form>
                <p>{getLineText(flag.from, flag.until)}</p>
                    
                <SelectWrapper>
                    <PrimerSelect
                        header="Flag Type"
                        options={FLAG_TYPE_OPTIONS}
                        type={type}
                        setType={setType}
                    />
                    <span style={{ width: 12 }}/>
                    <PrimerSelect
                        header="Priority"
                        summary={`${priority.text} priority`}
                        options={PRIORITY_OPTIONS}
                        type={priority}
                        setType={setPriority}
                    />
                </SelectWrapper>
                <br/>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </Form.Group>

                <ButtonPrimary
                    type="button"
                    onClick={save}
                >Save</ButtonPrimary>
            </Form>
        </Box>
    );
};

export default FlagForm;
