import React from 'react';
import { BrowserRouter as ReactRouter, Route, Switch } from 'react-router-dom';
import Nav from "../components/Nav";
// @ts-ignore
import { Helmet } from 'react-helmet';
import NotFound from '../components/NotFound';
import { routeList } from './routes';
import { RouteProps } from "./types";
import ModalContainer from "../components/ModalContainer";

const createTitle = (routeTitle: string) => {
    const titlePrefix = routeTitle && `${routeTitle} | `;
    return `${titlePrefix}Lousier`;
};

const createRouteRenderer = (route: RouteProps) => (props: any) => {
    const Component = route.component || NotFound;
    return <div>
        <Helmet>
            <title>{ createTitle(route.title || '') }</title>
        </Helmet>
        <Component {...props} />
    </div>
};

const Router = () =>
    <ReactRouter basename={process.env.REACT_APP_BASENAME}>
        <Nav/>
        <ModalContainer/>
        <Switch>
            {
                routeList.map((route, index) => (
                    <Route
                        path={route.path}
                        exact={route.exact}
                        render={createRouteRenderer(route)}
                        key={index}
                    />
                ))
            }
            <Route path="*" component={NotFound} />
        </Switch>
    </ReactRouter>;

export default Router;
