import React from 'react';
import { toApiUrl } from "./urls";
import {captureError} from "./errorTracker";
import Loader from "../components/Loader";

const getAbortController = () => {
    if (!window.AbortController) {
        // Don't abort requests if the browser doesn't support it
        return {
            abort: () => {},
            signal: null,
        }
    }

    return new window.AbortController();
};

const getPayload = async (response: Response) => {
    try {
        return await response.clone().json(); // Clone so .json can be called a second time
    } catch (e) {
        try {
            return await response.clone().text(); // Clone so .text can be called a second time
        } catch (e) {
            captureError(e);
            return undefined
        }
    }
};


type CancelableFetch = ReturnType<typeof createCancelableFetch>
const createCancelableFetch = (input: RequestInfo, options?: RequestInit) => {
    let { signal, abort } = getAbortController();
    let promise = fetch(input, { signal, ...options });
    return { promise, abort };
};

const cancelableFetchCreator = (input: RequestInfo, options?: RequestInit, cache?: boolean) => {
    if (cache) {
        // Only fetch once
        const cancelableFetch = createCancelableFetch(input, options);
        return () => cancelableFetch;
    }
    return () => createCancelableFetch(input, options);
};

export interface FetchHOCProps {
    // assume our api always returns object or list of objects
    data: object | object[] | undefined,
    response: Response | undefined,
    reFetch: () => void,
}

const _withFetchHOC = (createCancelableFetch: () => CancelableFetch) => Component =>
    class FetchHOC extends React.Component {
        _isMounted: boolean = false;
        _type = 'FetchHOC';
        cancelableFetch: CancelableFetch | undefined;

        state = {
            loading: true,
            success: undefined,
            error: undefined,
            data: undefined,
            response: undefined,
        };

        doFetch = () => {
            this.cancelableFetch = createCancelableFetch();
            this.cancelableFetch.promise
                .then(this.handleResolvedFetch)
                .catch(this.handleRejectedFetch);
        };

        componentDidMount = () => {
            this._isMounted = true;
            this.doFetch();
        };

        handleResolvedFetch = async (response: Response) => {
            if (!this._isMounted) { return; }
            const data = await getPayload(response);
            const success = response.ok && !!data;
            this.setState(() => ({
                data,
                error: !response.ok && new Error(response.statusText),
                loading: false,
                success,
                response,
            }));
        };

        handleRejectedFetch = async (error: Error) => {
            if (!this._isMounted) { return; }
            this.setState(() => ({
                error,
                loading: false,
                success: false,
            }));
        };

        componentWillUnmount = () => {
            this._isMounted = false;
            if (this.state.loading) {
                this.cancelableFetch && this.cancelableFetch.abort();
            }
        };

        render() {
            if (this.state.loading) {
                return <Loader />
            }

            if (!this.state.success) {
                const errorMessage = (this.state.error || {}).message || "Something went wrong";
                return <div>{errorMessage}</div>
            }

            const passedProps: FetchHOCProps = {
                data: this.state.data,
                response: this.state.response,
                reFetch: this.doFetch,
            };

            return <Component {...passedProps} />
        }
    };


/**
 * Working Example with API_URL = https://api.thedogapi.com

     const DOGGO_URL = '/v1/images/search';
     const Doggo = ({ data }: any) => <div>
             { data.map(({url}) => <img src={url} />) }
        </div>;
     const FetchDoggo = fetchWrapper(DOGGO_URL)(Doggo);
     <FetchDoggo/>
 */
export const withFetch = (path: string) => _withFetchHOC(cancelableFetchCreator(toApiUrl(path)));

/**
 * Working Example with API_URL = https://api.thedogapi.com

        const DOGGO_URL = '/v1/images/search';
        const FetchDoggo = withFetch(DOGGO_URL)(({ data }: any) => <div>
                { data.map(({url}) => <img src={url} />) }
            </div>);
        <FetchDoggo/>
 */
export const renderWithFetch = (path: string) => Component => {
    const WrapperComponent = withFetch(path)(Component);
    return <WrapperComponent />;
};
