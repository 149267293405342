const STYLES = {
    colors: {
        primaryDark: "#303F9F",
        primary: "#3F51B5",
        primaryLight: "#C5CAE9",
        primaryColorText: "#FFFFFF",
        accent: "#607D8B",
        primaryText: "#212121",
        secondaryText: "#757575",
        divider: "#BDBDBD",
    }
};

export default STYLES;
