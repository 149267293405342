import React from 'react';
import { BaseStyles } from '@primer/components'
import Router from './Router';
import { Provider } from 'react-redux'
import store from "./store";

const App = () =>
    <BaseStyles>
        <Provider store={store}>
            <Router />
        </Provider>
    </BaseStyles>;

export default App;
