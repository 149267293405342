import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { matchPath } from 'react-router';
import BootstrapNav from 'react-bootstrap/Nav';
import { Image } from "react-bootstrap";
import { RoutePropsWithPathResolver } from "../App/types";
import { navRoutes } from "../App/routes";
import styled from "styled-components";
import STYLES from "../constants/styles";
import { DUMMY_USER } from "../features/users/redux";

const NavWrapper = styled.div`
    background-color: ${STYLES.colors.primary};
    * { color: ${STYLES.colors.primaryColorText}; }
`;

const UserThumbnail = styled.div`
    margin-left: auto;
    margin-right: 0;
    margin-top: auto;
    margin-bottom: auto;
`;

const Nav = () => {
    const history = useHistory();
    const isRouteActive = (route: RoutePropsWithPathResolver) => matchPath(history.location.pathname, route);
    const getRouteStyle = route => isRouteActive(route)
        ? { fontWeight: 900 }
        : {};
    return (
        <NavWrapper>
            <BootstrapNav aria-label="Main" className="container">
                { navRoutes.map(route =>
                    <BootstrapNav.Item key={`${route.path}`}>
                        <BootstrapNav.Link
                            style={getRouteStyle(route)}
                            as={Link}
                            to={route.getPath()}
                        >
                            {route.title}
                        </BootstrapNav.Link>
                    </BootstrapNav.Item>
                )}

                <UserThumbnail>
                    <Image
                        src={DUMMY_USER.thumbnail}
                        roundedCircle
                        style={{ height: 30 }}
                    />
                </UserThumbnail>
            </BootstrapNav>
        </NavWrapper>
    )
};

export default Nav;
