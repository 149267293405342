import FileBrowserView from "./views/FileBrowserView";
import FileView from "./views/FileView";

export default {
    files: {
        title: 'Files',
        path: '/files',
        component: FileBrowserView,
        includeInNav: true,
        exact: true,
        icon: "someicon",
    },
    file: {
        title: 'File',
        path: '/files/*',
        component: FileView,
        exact: true,
        getPath: ({ file='' }) => '/files/*'.replace("*", file),
    },
};
