import React from 'react';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import { FlagType } from "../types";
import { editFlag } from "../redux";
import { connect } from "react-redux";
import FlagForm from "./FlagForm";
import { Image, Row } from "react-bootstrap";

interface EditFlagModalProps {
    editFlag: (flag: FlagType) => void;
    close: () => void;
    flag: FlagType;
}

const EditFlag = ({ close, editFlag, flag}: EditFlagModalProps) => {
    const edit = (flag: FlagType) => {
        editFlag(flag);
        close();
    };

    return <React.Fragment>

        <Row style={{ padding: "0 40px", display: "flex" }}>
            <Image
                src={flag.createdBy.thumbnail}
                roundedCircle
                style={{ height: 40 }}
            />
            <p style={{ margin: "auto", marginLeft: 12 }}>Created by {flag.createdBy.name}</p>
        </Row>

        <FlagForm
            flag={flag}
            submit={edit}
        />
    </React.Fragment>;
};

const mapDispatchToProps = dispatch => ({
    editFlag: (flag: FlagType) => dispatch(editFlag(flag)),
});

export default connect(null, mapDispatchToProps)(EditFlag);
