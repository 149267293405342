import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from "react-router-dom";
import { FlagType } from "../types";
import { connect } from "react-redux";
import sortBy from 'lodash/sortBy';

interface FlagsPerPriorityProps {
    flags: FlagType[],
}

const COLUMNS = [
    {
        dataField: 'priority.text',
        text: 'Priority'
    },
    {
        dataField: 'flags',
        text: 'Flags'
    },
    {
        dataField: 'lines',
        text: 'Total Lines'
    },
];

const prepareData = flags => {
    const flagsPerPriority = {};
    flags.forEach(flag => {
        flagsPerPriority[flag.priority.value] =
            flagsPerPriority[flag.priority.value] || { lines: 0, flags: 0 };
        flagsPerPriority[flag.priority.value].lines += (flag.until - flag.from + 1);
        flagsPerPriority[flag.priority.value].flags += 1;
        flagsPerPriority[flag.priority.value].priority = flag.priority;
    });
    return sortBy(Object.values(flagsPerPriority), 'flags').reverse();
};

const FlagsPerPriority = ({ flags }: FlagsPerPriorityProps) => {
    const history = useHistory();
    const data = prepareData(flags);
    return <BootstrapTable
        keyField='id'
        data={data}
        columns={COLUMNS}
    />;
};

// @ts-ignore
const mapStateToProps = (state) => ({
    flags: state.flags || [],
});

export default connect(mapStateToProps)(FlagsPerPriority)
