import React from 'react';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import KeyedFileBrowser, { Icons } from 'react-keyed-file-browser';
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import files from '../../../demodata/files/fileData.json';

const Wrapper = styled.div`
  a {
    color: unset;
    text-decoration: none;
  }
`;

// Workaround to open file
const OpenFile = props => {
    const history = useHistory();
    // eslint-disable-next-line react/prop-types
    history.push(`files/${props.file.key}`);
    return <div> </div>;
};

const FileBrowser = () =>
    <Wrapper>
        <KeyedFileBrowser
            files={files}
            icons={Icons.FontAwesome(4)}
            detailRenderer={OpenFile}
        />
    </Wrapper>;

export default FileBrowser
