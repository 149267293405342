import { addPathResolvers } from "../utils/routes";
import { RoutesMap, RouteProps } from "./types";
import Home from "./Home";

// Routes
import fileBrowserRoutes from '../features/filebrowser/routes';
import repoRoutes from '../features/repos/routes';
const validateRoutes = (routes: RouteProps[]) =>  {
    routes.forEach(route => {
        if (route.includeInNav) {
            if (!route.icon) { throw Error("Icon is required if route is included in nav")}
            if (!route.title) { throw Error("Title is required if route is included in nav")}
        }
    });
};

// For lazy-loading routes - not necessary for now.
// const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: renderLoader });

const routeMap: RoutesMap = {
    home: {
        title: 'Home',
        path: '/',
        component: Home,
        includeInNav: true,
        exact: true,
        icon: "home",
    },
    ...repoRoutes,
    ...fileBrowserRoutes,
};

const routes = addPathResolvers(routeMap);
const routeList = Object.values(routes);
validateRoutes(routeList);

export const navRoutes = routeList.filter(r => r.includeInNav);
export { routeList };
export default routes;
