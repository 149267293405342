import React from 'react';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import { UnsavedFlagType } from "../types";
import { addFlag } from "../redux";
import { connect } from "react-redux";
import FlagForm from "./FlagForm";

interface CreateFlagModalProps {
    addFlag: (flag: UnsavedFlagType) => void;
    close: () => void;
    from: number;
    until: number;
    file: string;
}

const CreateFlag = ({ close, addFlag, from, until, file }: CreateFlagModalProps) => {
    const create = (flag: UnsavedFlagType) => {
        addFlag(flag);
        close();
    };

    return (
        <FlagForm
            flag={{ from, until, file }}
            submit={create}
        />
    );
};

const mapDispatchToProps = dispatch => ({
    addFlag: (flag: UnsavedFlagType) => dispatch(addFlag(flag)),
});

export default connect(null, mapDispatchToProps)(CreateFlag);
