import React from 'react'
import { useHistory } from "react-router-dom";
import File from "../components/File";
import { getFilePathFromUrl } from "../services";
import { renderWithFetch } from "../../../utils/fetch";
import styled from "styled-components";

// eslint-disable-next-line react/prop-types
const FileFetcher = ({ filePath }) => renderWithFetch(`${filePath}`)(
    ({ data }) => <File data={data} filePath={filePath}/>
);

const FilePath = styled.h5`
    font-weight: bold;
    margin-bottom: 18px;
`;

const FileView = () => {
    const history = useHistory();
    const filePath = getFilePathFromUrl(history.location.pathname);
    return (
        <div className="container" style={{ paddingTop: 24 }}>
            <FilePath>/{filePath}</FilePath>
            <FileFetcher filePath={filePath}/>
        </div>
    );
};

export default FileView;
