import React from 'react'
import Card from "react-bootstrap/Card";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import {Link} from "react-router-dom";
import routes from "../../../App/routes";

const Row = styled.div`
    display: flex;
    padding-top: 24px;
    margin-right: -24px;
`;

const styles = {
    card: { flexGrow: 1, marginRight: 24 }
};

const RepoCard = () =>
    <Card style={styles.card}>
        <Card.Header style={{ marginBottom: 24 }}>Some Repo</Card.Header>
        <Card.Body>
            <p>24 Flags</p>
            <p>4 Users</p>
            <Link to={routes.dashboard.getPath()}>
                <Button variant="secondary">
                    Dashboard
                </Button>
            </Link>

            <Link to={routes.files.getPath()} style={{ marginLeft: 12 }}>
                <Button variant="secondary">
                    Files
                </Button>
            </Link>
        </Card.Body>
    </Card>;

const RepoOverview = () =>
    <div>
        <Link to={routes.newRepo.getPath()}>
            <Button>New repo</Button>
        </Link>

        <Row>
            <RepoCard/>
            <RepoCard/>
            <RepoCard/>
        </Row>
    </div>;

export default RepoOverview
