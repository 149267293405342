import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { FlagType } from "../types";
import { connect } from "react-redux";
import { getLineRange } from "../utils";
import { useHistory } from "react-router-dom";

interface FlagListProps {
    flags: FlagType[],
    flagsFilter?: (flag: FlagType) => boolean
}

const COLUMNS = [
    {
        dataField: 'lines',
        text: 'Lines'
    }, 
    {
        dataField: 'priority.text',
        text: 'Priority'
    }, {
        dataField: 'type.text',
        text: 'Type'
    }
];

const prepareData = (flags: FlagType[]) => flags.map(flag => ({
    lines: getLineRange(flag.from, flag.until),
    ...flag,
}));

const FlagList = ({ flags, flagsFilter = v => !!v }: FlagListProps) => {
    const history = useHistory();
    const data = prepareData(flags.filter(flagsFilter));
    return <BootstrapTable
        keyField='id'
        data={data}
        columns={COLUMNS}
        rowEvents={{
            onClick: (e, row, rowIndex) => {
                history.push("/files/" + row.file);
            }
        }}
    />;
};

// @ts-ignore
const mapStateToProps = (state) => ({
    flags: state.flags || [],
});

export default connect(mapStateToProps)(FlagList)
