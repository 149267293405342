import React from 'react'
import FileBrowser from "../components/FileBrowser";

const FileBrowserView = () =>
    <div className="container">
        <br/>
        <h1>Browse Files</h1>
        <FileBrowser/>
    </div>;

export default FileBrowserView;
