import {UserType} from "./types";

const SET_CURRENT_USER = 'ADD_USER';

export const setCurrentUser = () => ({
    type: SET_CURRENT_USER,
    user: {},
});

export const DUMMY_USER = {
    thumbnail: "https://res.cloudinary.com/vertaalapp/image/upload/c_fill,h_200,w_200/v1580059470/o48g8hr77yzabwylh2ht.jpg",
    name: "Miguel Gomes",
    email: "Miguel.Gomes@madooz.com",
} as UserType;

const users = (state = DUMMY_USER , action) => {
    const setAction = (action) => action.user;
    switch (action.type) {
    case SET_CURRENT_USER: return setAction(action);
    default: return state
    }
};

export const usersReducer = (state, action) => users(state, action);
