import {FlagType, UnsavedFlagType} from "./types";
import {DUMMY_USER} from "../users/redux";

const toObj = v => v && JSON.parse(v);
const toStr = v => v && JSON.stringify(v);
const FLAGS_KEY = 'flags__006';
const getFlags = () => toObj(localStorage.getItem(FLAGS_KEY)) || [];
const setFlags = state => {
    localStorage.setItem(FLAGS_KEY, toStr(state));
    return state
};

const ADD_FLAG = 'ADD_FLAG';
const EDIT_FLAG = 'EDIT_FLAG';

let _nextFlagId = getFlags().length;
export const addFlag = (flag: UnsavedFlagType) => ({
    type: ADD_FLAG,
    flag: {
        ...flag,
        createdBy: DUMMY_USER,
        id: ++_nextFlagId,
        created: new Date(),
    } as FlagType,
});

export const editFlag = (flag: FlagType) => ({
    type: EDIT_FLAG,
    flag,
});

const flags = (state = getFlags(), action) => {
    const addAction = action => [...state, {...action.flag }];
    const editAction = action => state.map(
        flag => flag.id === action.flag.id
            ? action.flag
            : flag
    );
    switch (action.type) {
    case ADD_FLAG: return addAction(action);
    case EDIT_FLAG: return editAction(action);
    default: return state
    }
};

export const flagsReducer = (state, action) => setFlags(flags(state, action));
