import React from 'react';
import { DUMMY_USER } from "../redux";
import styled from "styled-components";

const Wrapper = styled.div`
    padding: 24px 0;
`;

const Header = styled.h5`
    margin: 12px 0 24px;
`;

const CurrentUser = () =>
    <Wrapper>
        <Header>Welcome back {DUMMY_USER.name}</Header>
    </Wrapper>;

export default CurrentUser;
