import { formatRoute } from 'react-router-named-routes';
import { RoutesMap, RoutesMapWithPathResolvers } from "../App/types";

export const addPathResolvers = (routeMap: RoutesMap): RoutesMapWithPathResolvers => {
    const newRouteMap = {};
    Object.keys(routeMap).forEach(key => {
        newRouteMap[key] = {
            ...routeMap[key],
            /**
             * use getPath to get the path of a route. Example:
             * <Link to={routes.dashboard.formatRoute()} />
             * or if route accept params like "user/:id":
             * <Link to={routes.userDetails.formatRoute({ id: 1 })} />
             */
            getPath: (params: object | undefined) =>
                routeMap[key].getPath ||
                formatRoute(routeMap[key].path, params),
        }
    });
    return newRouteMap;
};
