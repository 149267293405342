import { combineReducers, createStore } from 'redux';
import { flagsReducer } from "../features/flags/redux";
import { modalsReducer } from "../redux";
import { usersReducer } from "../features/users/redux";

const rootReducer = combineReducers({
    flags: flagsReducer,
    modals: modalsReducer,
    users: usersReducer,
});
const store = createStore(rootReducer);
export default store
