import React from 'react'
import Card from "react-bootstrap/Card";
import FlagsOverTimeChart from "../../../components/FlagsOverTimeChart";
import FlagList from "../../flags/components/FlagList";
import FlagsPerFile from "../../flags/components/FlagsPerFile";
import FlagsPerPriority from "../../flags/components/FlagsPerPriority";
import {Col, Row} from "react-bootstrap";

const styles = {
    card: { flexGrow: 1, border: 0 }
};

const Dashboard = () =>
    <div className="container">
        <h3 style={{ marginTop: 24 }}>Dashboard with useful insights and analytics</h3>
        <Card>
            <FlagsOverTimeChart />
        </Card>

        <br/>

        <Row>
            <Col>
                <Card style={styles.card}>
                    <Card.Header>High priority flags</Card.Header>
                    <FlagList />
                </Card>
            </Col>

            <Col>
                <Card style={styles.card}>
                    <Card.Header>New flags</Card.Header>
                    <FlagList />
                </Card>
            </Col>
        </Row>

        <Row>
            <Col>
                <Card style={styles.card}>
                    <Card.Header>Flagged files</Card.Header>
                    <FlagsPerFile />
                </Card>
            </Col>

            <Col>
                <Card style={styles.card}>
                    <Card.Header>Priority of flags</Card.Header>
                    <FlagsPerPriority />
                </Card>
            </Col>
        </Row>
    </div>;

export default Dashboard
