import React from 'react'
import { Chart } from "react-google-charts";

const unixDay = 86400000;

let _prevTime = Date.now();
const getPreviousDay = () => {
    _prevTime -= unixDay;
    return new Date(_prevTime);
};
const Data = (value) => [
    getPreviousDay(),
    value,
];

const dummyData = [
    ["time", "flags"],
    Data(28),
    Data(25),
    Data(29),
    Data(29),
    Data(32),
    Data(39),
    Data(39),
    Data(31),
    Data(31),
    Data(31),
    Data(45),
    Data(45),
];

const FlagsOverTimeChart = () =>
    <Chart
        width={'100%'}
        height={'300px'}
        chartType="LineChart"
        data={dummyData}
        options={{
            hAxis: {
                format: 'dd/MM'
            }
        }}
    />;

export default FlagsOverTimeChart;
