import React from 'react';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import styled from "styled-components";
import parse from 'html-react-parser';
import { BorderBox } from "@primer/components";
import { SelectableGroup, createSelectable } from 'react-selectable-fast'
import { connect } from "react-redux";
import { FlagType } from "../../flags/types";
import FlagList from "../../flags/components/FlagList";
import { getPriority } from "../../flags/constants";
import { addModal } from "../../../redux";
import { ModalChildProps } from "../../../types";
import store from "../../../App/store";
import CreateFlag from "../../flags/components/CreateFlag";
import EditFlag from "../../flags/components/EditFlag";

const Row = styled.div`
    height: 20px;
    overflow: hidden;
    line-height: 1.25em;
    display: flex;
`;

const LineNumber = styled.span`
    width: 40px;
    color: #bfbfbf;
    text-align: center;
    font-size: 12px;
`;

const LineContent = styled.span`
    margin-left: 24px;
    white-space:pre; // show indentation whitespace
    display: block;
    font-family: monospace;
`;

const CodeWrapper = styled.div`
    width: 100%;
    overflow-x: auto;
    background-color: #f5f5f5;
    padding: 12px 0;
`;

const SelectableRow = createSelectable(({ selectableRef, isSelected = false, isSelecting, children, line }) => {
    const style = (isSelected || isSelecting)
        ? {backgroundColor: 'rgba(0,226,225,0.1)'}
        : {};
    return (
        <Row ref={selectableRef} style={style} key={line}>
            {children}
        </Row>
    );
});

interface FileProps {
    data: string,
    filePath: string,
    flags: FlagType[],
}

let _key = 0;

const createSelectionFinishHandler = (file: string) => selectedComponents => {
    if (selectedComponents.length < 1) {
        return;
    }

    const firstComponent = selectedComponents[0];
    const lastComponent = selectedComponents[selectedComponents.length - 1];
    let from = firstComponent.props.line;
    let until = lastComponent.props.line;

    if (from > until) {
        [from, until] = [until, from];
    }

    const Component = ({ close }: ModalChildProps) =>
        <CreateFlag
            close={close}
            from={from}
            until={until}
            file={file}
        />;
    store.dispatch(addModal({
        header: "Create Flag",
        Component,
    }));
};

const openFlagModal = (flag: FlagType) => {
    const Component = ({ close }: ModalChildProps) =>
        <EditFlag close={close} flag={flag}/>;

    store.dispatch(addModal({
        header: "Flag",
        Component,
    }))
};

const renderFlag = (flags: FlagType[], index: number) => {
    const line = index + 1;
    const flag = flags.find(
        flag => (flag.from <= line && flag.until >= line)
    );

    if (!flag) {
        return null
    }

    return <i
        onClick={() => openFlagModal(flag)}
        style={{
            color: getPriority(flag.priority.value).color,
            position: "absolute",
            marginTop: 4,
            marginLeft: -4,
        }}
        className="fa fa-flag"
    />;
};

const File = ({ data, filePath, flags }: FileProps) => {
    const key = ++_key;  // Rerender "SelectableGroup" on every render.
    const flagsOfFile = flags.filter(flag => flag.file === filePath);
    const lines = data.split("\n");
    return (
        <div>
            {
                flagsOfFile.length > 0 &&  <FlagList flagsFilter={({ file }) => file === filePath} />
            }
            <BorderBox>
                <CodeWrapper>
                    <SelectableGroup
                        key={key}
                        enableDeselect={false}
                        tolerance={0}
                        resetOnStart
                        allowClickWithoutSelected={false}
                        onSelectionFinish={createSelectionFinishHandler(filePath)}
                    >
                        { lines.map((code, index) =>
                            <SelectableRow key={index} line={index + 1}>
                                {renderFlag(flagsOfFile, index)}
                                <LineNumber>{index + 1}</LineNumber>
                                <LineContent>{parse(code)}</LineContent>
                            </SelectableRow>
                        )}
                    </SelectableGroup>
                </CodeWrapper>
            </BorderBox>
        </div>
    );
};

// @ts-ignore
const mapStateToProps = (state) => ({
    flags: state.flags || [],
});

const FileContainer = connect(mapStateToProps)(File);

export default FileContainer;
