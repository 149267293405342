import { FlagOption, PriorityOption } from "./types";
export const FLAG_TYPE_OPTIONS: FlagOption[] = [
    { value: 'complex', text: "Too complex" },
    { value: 'readability', text: "Difficult to read" },
    { value: 'coverage', text: "Low coverage" },
    { value: 'cases', text: "Missing cases" },
    { value: 'unsafe', text: "Unsafe code" },
    { value: 'comments', text: "Requires explanation" },
    { value: 'security', text: "Security issue" },
    { value: 'sideeffects', text: "Unexpected side effects" },
    { value: 'abstraction', text: "Wrong abstraction" },
    { value: 'styling', text: "Incorrect code style" },
];
export const DEFAULT_FLAG_TYPE = FLAG_TYPE_OPTIONS[0];
export const PRIORITY_OPTIONS: PriorityOption[] = [
    { value: 0, text: "Lowest", color: "#00fcff" },
    { value: 1, text: "Low", color: "#8362ff" },
    { value: 2, text: "Average", color: "#ffbb62" },
    { value: 3, text: "High", color: "#ff683e" },
    { value: 4, text: "Highest", color: "#ff201e" },
];
const PRIORITY_MAP = PRIORITY_OPTIONS.reduce(
    (obj, option) => ({...obj, [option.value]: option}), {}
);
export const getPriority = value => PRIORITY_MAP[value] || {};
export const DEFAULT_PRIORITY = PRIORITY_OPTIONS[2];
